<template>
	<modal name="ClvModalUsers" class="ClvModalUsers" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="clv_modal">
			<div class="clv_modal_head">
				<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="clv_modal_title">
					<span v-if="clvType == 'addUser'">Добавление пользователя</span>
					<span v-else-if="clvType == 'editUser'">Редактирование пользователя</span>
					<span v-else-if="clvType == 'deleteUser'">Удаление пользователя</span>
				</div>
			</div>
			<div class="clv_modal_body">
				<form>
					<div class="clv_modal_info">
						<div v-if="['addUser', 'editUser'].includes(clvType)">
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Группа:</div>
								<div class="clv_modal_info_row_value">
									<v-select class="clv_select style-chooser" v-model.trim="clvGroup" placeholder="выберите группу пользователя" :options="$parent.clvUserGroups"></v-select>
									<div v-html="clvAlertGroup"></div>
								</div>
							</div>
							<div class="clv_modal_info_row" v-if="$parent.clvUserGroups.indexOf(clvGroup) == 2">
								<div class="clv_modal_info_row_label">Источник:</div>
								<div class="clv_modal_info_row_value">
									<v-select class="clv_select style-chooser" v-model.trim="clvSource" placeholder="выберите источник парсера" :options="$parent.clvParserSources"></v-select>
									<div v-html="clvAlertSource"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">E-mail:</div>
								<div class="clv_modal_info_row_value">
									<input type="text" class="clv_input" v-model.trim="clvEmail" placeholder="e-mail пользователя" autocomplete="off">
									<div v-html="clvAlertEmail"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Телефон:</div>
								<div class="clv_modal_info_row_value">
									<input type="text" class="clv_input" v-model.trim="clvPhone" placeholder="телефон пользователя" autocomplete="off">
									<div v-html="clvAlertPhone"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Псевдоним:</div>
								<div class="clv_modal_info_row_value">
									<input type="text" class="clv_input" v-model.trim="clvUsername" placeholder="псевдоним пользователя" autocomplete="off">
									<div v-html="clvAlertUsername"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Пароль:</div>
								<div class="clv_modal_info_row_value">
									<input type="password" class="clv_input" v-model.trim="clvPassword" placeholder="пароль пользователя" autocomplete="off">
									<div v-html="clvAlertPassword"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Повтор пароля:</div>
								<div class="clv_modal_info_row_value">
									<input type="password" class="clv_input" v-model.trim="clvRePassword" placeholder="повтор пароля пользователя" autocomplete="off">
									<div v-html="clvAlertRePassword"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Страна</div>
								<div class="clv_modal_info_row_value">
									<v-select class="clv_select style-chooser" v-model.trim="clvCountry" placeholder="выберите страну" :options="$parent.$parent.$parent.sortDataByLabel($parent.$parent.$parent.getSelectOptions(1), $store.state.clvLanguage)" :label="'label_' + $store.state.clvLanguage"></v-select>
									<div v-html="clvAlertCountry"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Баланс:</div>
								<div class="clv_modal_info_row_value">
									<input type="text" class="clv_input" v-model.trim="clvBalance" placeholder="баланс пользователя" autocomplete="off">
									<div v-html="clvAlertBalance"></div>
								</div>
							</div>
							<div class="clv_modal_info_row">
								<div class="clv_modal_info_row_label">Вес:</div>
								<div class="clv_modal_info_row_value">
									<input type="text" class="clv_input" v-model.trim="clvWeight" placeholder="вес пользователя" autocomplete="off">
									<div v-html="clvAlertWeight"></div>
								</div>
							</div>
							<div class="clv_modal_info_row last">
								<div class="clv_modal_info_row_label">Дата голосования:</div>
								<div class="clv_modal_info_row_value">
									<div class="clv_modal_info_row_value_text">{{ (clvVotedAt ? new Date(clvVotedAt * 1000).toLocaleString($store.state.clvLanguage) : '−') }}</div>
								</div>
							</div>
						</div>
						<div v-else-if="clvType == 'deleteUser'">
							<p><b>Внимание</b>! Вы действительно хотите удалить пользователя из системы со всеми его данными?</p>
						</div>
					</div>
					<div class="clv_modal_alert" v-html="clvAlert"></div>
					<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
						<button type="button" class="clv_button common" @click="close">Закрыть</button>
						<button type="submit" class="clv_button common green" @click="axios" v-if="clvType == 'addUser'">Добавить</button>
						<button type="submit" class="clv_button common green" @click="axios" v-else-if="clvType == 'editUser'">Сохранить</button>
						<button type="submit" class="clv_button common red" @click="axios" v-else-if="clvType == 'deleteUser'">Удалить</button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvData: {},
			clvAlert: '',
			clvAlertGroup: '',
			clvAlertSource: '',
			clvAlertEmail: '',
			clvAlertPhone: '',
			clvAlertUsername: '',
			clvAlertPassword: '',
			clvAlertRePassword: '',
			clvAlertCountry: '',
			clvAlertBalance: '',
			clvAlertWeight: '',
			clvId: 0,
			clvType: '',
			clvIndex: 0,
			clvGroup: '',
			clvSource: '',
			clvEmail: '',
			clvPhone: '',
			clvUsername: '',
			clvPassword: '',
			clvRePassword: '',
			clvCountry: '',
			clvBalance: '',
			clvWeight: '',
			clvVotedAt: 0,
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.clvData = {};
				this.resetAlerts();
				this.clvId = event.params.id;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvGroup = '';
				this.clvSource = '';
				this.clvEmail = '';
				this.clvPhone = '';
				this.clvUsername = '';
				this.clvPassword = '';
				this.clvRePassword = '';
				this.clvCountry = '';
				this.clvBalance = '';
				this.clvWeight = '';
				this.clvVotedAt = 0;
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (this.clvType == 'editUser') {

					this.clvData = this.$parent.clvUsers[this.clvIndex];
					this.clvGroup = this.$parent.clvUserGroups[this.clvData.group];
					this.clvSource = this.clvData.source;
					this.clvEmail = this.clvData.email;
					this.clvPhone = this.clvData.phone;
					this.clvUsername = this.clvData.username;
					this.clvPassword = '..........';
					this.clvRePassword = '..........';
					this.clvBalance = this.clvData.balance.toFixed(2);
					this.clvWeight = this.clvData.weight;
					this.clvVotedAt = this.clvData.voted_at;

					for (var i in this.$parent.$parent.$parent.getSelectOptions(1)) {
						if (this.$parent.$parent.$parent.getSelectOptions(1)[i].code == this.$parent.clvUsers[this.clvIndex].country) {
							this.clvCountry = this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
						}
					}
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertGroup = '';
				this.clvAlertSource = '';
				this.clvAlertEmail = '';
				this.clvAlertPhone = '';
				this.clvAlertUsername = '';
				this.clvAlertPassword = '';
				this.clvAlertRePassword = '';
				this.clvAlertCountry = '';
				this.clvAlertBalance = '';
				this.clvAlertWeight = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать id пользователя.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный id пользователя.'; }
				else if (i == 3) { this.clvTemp = 'Необходимо выбрать группу пользователя.'; }
				else if (i == 4) { this.clvTemp = 'Необходимо выбрать верную группу пользователя.'; }
				else if (i == 5) { this.clvTemp = 'Необходимо выбрать источник парсера.'; }
				else if (i == 6) { this.clvTemp = 'Необходимо выбрать верный источник парсера.'; }
				else if (i == 7) { this.clvTemp = 'Необходимо указать e-mail пользователя.'; }
				else if (i == 8) { this.clvTemp = 'Необходимо указать верный e-mail пользователя.'; }
				else if (i == 9) { this.clvTemp = 'Необходимо указать телефон пользователя.'; }
				else if (i == 10) { this.clvTemp = 'Необходимо указать верный телефон пользователя. Формат: +Ххххххххххх.'; }
				else if (i == 11) { this.clvTemp = 'Необходимо указать псевдоним пользователя.'; }
				else if (i == 12) { this.clvTemp = 'Необходимо указать верный псевдоним пользователя. Разрешено вводить буквы, цифры, точку, тире, знак подчеркивания и пробел. Длина: от 4 до 30 символов.'; }
				else if (i == 13) { this.clvTemp = 'Необходимо указать пароль пользователя.'; }
				else if (i == 14) { this.clvTemp = 'Необходимо указать верный пароль пользователя. Разрешено вводить только буквы и цифры. Длина: от 6 до 30 символов. Обязательно: хотя бы одна буква.'; }
				else if (i == 15) { this.clvTemp = 'Необходимо указать повтор пароля пользователя.'; }
				else if (i == 16) { this.clvTemp = 'Указанные пароли пользователя не совпадают.'; }
				else if (i == 17) { this.clvTemp = 'Необходимо выбрать страну.'; }
				else if (i == 18) { this.clvTemp = 'Необходимо выбрать верную страну.'; }
				else if (i == 19) { this.clvTemp = 'Необходимо указать баланс пользователя.'; }
				else if (i == 20) { this.clvTemp = 'Необходимо указать верный баланс пользователя.'; }
				else if (i == 21) { this.clvTemp = 'Необходимо указать вес пользователя.'; }
				else if (i == 22) { this.clvTemp = 'Необходимо указать верный вес пользователя. Диапазон: от 0 до 100.'; }
				else if (i == 23) { this.clvTemp = 'Необходимо указать верный вес пользователя. Для выбранной группы он равен 0.'; }
				else if (i == 24) { this.clvTemp = 'Id пользователя не найден в системе.'; }
				else if (i == 25) { this.clvTemp = 'Источник парсера не найден в системе.'; }
				else if (i == 26) { this.clvTemp = 'Такой e-mail пользователя уже есть в системе.'; }
				else if (i == 27) { this.clvTemp = 'Данного пользователя нельзя удалить из системы.'; }
				else              { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			axios: function(event) {

				event.preventDefault();

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						url: '/v2/admin/account/users',
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (['addUser', 'editUser'].includes(_this.clvType)) {
						if (_this.clvGroup === '') {
							_this.clvAlertGroup = _this.getError(3);
						}
						else if (_this.$parent.clvUserGroups.indexOf(_this.clvGroup) == 2 && _this.clvSource === '') {
							_this.clvAlertSource = _this.getError(5);
						}
						if (!_this.clvEmail) {
							_this.clvAlertEmail = _this.getError(7);
						}
						else if (!_this.$store.state.clvRegexEmail.test(_this.clvEmail)) {
							_this.clvAlertEmail = _this.getError(8);
						}
						if (_this.clvPhone && !_this.$store.state.clvRegexPhone.test(_this.clvPhone)) {
							_this.clvAlertPhone = _this.getError(10);
						}
						if (!_this.clvUsername) {
							_this.clvAlertUsername = _this.getError(11);
						}
						else if (!_this.$store.state.clvRegexUsername.test(_this.clvUsername)) {
							_this.clvAlertUsername = _this.getError(12);
						}
						if (!_this.clvPassword) {
							_this.clvAlertPassword = _this.getError(13);
						}
						else if (_this.clvPassword !== '..........' && (!_this.$store.state.clvRegexPassword.test(_this.clvPassword) || _this.clvPassword.search(_this.$store.state.clvRegexSearchENRULetters) < 0)) {
							_this.clvAlertPassword = _this.getError(14);
						}
						if (!_this.clvRePassword) {
							_this.clvAlertRePassword = _this.getError(15);
						}
						if (_this.clvPassword && _this.clvRePassword && _this.clvPassword != _this.clvRePassword) {
							_this.clvAlertRePassword = _this.getError(16);
						}
						if (!_this.clvCountry) {
							_this.clvAlertCountry = _this.getError(17);
						}
						else if (_this.clvCountry.code == undefined) {
							_this.clvAlertCountry = _this.getError(18);
						}
						if (!_this.clvBalance) {
							_this.clvAlertBalance = _this.getError(19);
						}
						else if (!_this.$store.state.clvRegexBalance.test(_this.clvBalance)) {
							_this.clvAlertBalance = _this.getError(20);
						}
						if (_this.clvWeight === '') {
							_this.clvAlertWeight = _this.getError(21);
						}
						else if (!_this.$store.state.clvRegex_d.test(_this.clvWeight) || Number(_this.clvWeight) < 0 || Number(_this.clvWeight) > 100) {
							_this.clvAlertWeight = _this.getError(22);
						}
						else if ([1,3].includes(_this.$parent.clvUserGroups.indexOf(_this.clvGroup)) && _this.clvWeight > 0) {
							_this.clvAlertWeight = _this.getError(23);
						}
						if (!_this.clvIsError) {
							config.data = { id: _this.clvId, group: _this.$parent.clvUserGroups.indexOf(_this.clvGroup), source: _this.clvSource, email: _this.clvEmail, phone: _this.clvPhone, username: _this.clvUsername, password: _this.clvPassword, country: _this.clvCountry.code, balance: Number(_this.clvBalance), weight: Number(_this.clvWeight) };
							config.method = _this.clvType == 'addUser' ? 'post' : 'patch';
						}
					}
					else if (_this.clvType == 'deleteUser') {
						config.data = { id: _this.clvId };
						config.method = 'delete';
					}

					if (!_this.clvIsError) {
						_this.clvIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'addUser') {
									data.data.phone = (data.data.phone !== '' ? '+' : '') + data.data.phone;
									_this.$parent.clvUsers.push(data.data);
								}
								else if (_this.clvType == 'editUser') {
									data.data.phone = (data.data.phone !== '' ? '+' : '') + data.data.phone;
									_this.$parent.clvUsers.splice(_this.clvIndex, 1, data.data);
								}
								else if (_this.clvType == 'deleteUser') {
									_this.$parent.clvUsers.splice(_this.clvIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addUser', 'editUser', 'deleteUser'].includes(_this.clvType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.clvAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.clvAlert = _this.getError(2); }
										else if (data.response.data.error == 'GROUP_IS_EMPTY') { _this.clvAlertGroup = _this.getError(3); }
										else if (data.response.data.error == 'GROUP_NOT_VALID') { _this.clvAlertGroup = _this.getError(4); }
										else if (data.response.data.error == 'SOURCE_IS_EMPTY') { _this.clvAlertSource = _this.getError(5); }
										else if (data.response.data.error == 'SOURCE_NOT_VALID') { _this.clvAlertSource = _this.getError(6); }
										else if (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.clvAlertEmail = _this.getError(7); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.clvAlertEmail = _this.getError(8); }
										else if (data.response.data.error == 'PHONE_IS_EMPTY') { _this.clvAlertPhone = _this.getError(9); }
										else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.clvAlertPhone = _this.getError(10); }
										else if (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.clvAlertUsername = _this.getError(11); }
										else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.clvAlertUsername = _this.getError(12); }
										else if (data.response.data.error == 'PASSWORD_IS_EMPTY') { _this.clvAlertPassword = _this.getError(13); }
										else if (data.response.data.error == 'PASSWORD_NOT_VALID') { _this.clvAlertPassword = _this.getError(14); }
										else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.clvAlertCountry = _this.getError(17); }
										else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.clvAlertCountry = _this.getError(18); }
										else if (data.response.data.error == 'BALANCE_IS_EMPTY') { _this.clvAlertBalance = _this.getError(19); }
										else if (data.response.data.error == 'BALANCE_NOT_VALID') { _this.clvAlertBalance = _this.getError(20); }
										else if (data.response.data.error == 'WEIGHT_IS_EMPTY') { _this.clvAlertWeight = _this.getError(21); }
										else if (data.response.data.error == 'WEIGHT_NOT_VALID') { _this.clvAlertWeight = _this.getError(22); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addUser', 'editUser', 'deleteUser'].includes(_this.clvType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.clvAlert = _this.getError(24); }
										else if (data.response.data.error == 'SOURCE_NOT_FOUND') { _this.clvAlertSource = _this.getError(25); }
									}
								}
								else if (data.response.status == 409) {
									if (['addUser', 'editUser'].includes(_this.clvType)) {
										if (data.response.data.error == 'EMAIL_DUPLICATE') { _this.clvAlertEmail = _this.getError(26); }
									}
									else if (_this.clvType == 'deleteUser') {
										if (data.response.data.error == 'CANNOT_BE_DELETED') { _this.clvAlert = _this.getError(27); }
									}
								}
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('ClvModalUsers');
			}
		}
	}
</script>
